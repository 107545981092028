/* header */
.header {
    width: 100%;
    height: 40px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(
        180deg,
        rgba(20, 20, 20, 0.5) 0%,
        rgba(20, 20, 20, 0.3) 25%,
        rgba(20, 20, 20, 0) 75%
    );
    z-index: 1;
    transition: all 700ms ease-in-out;
    img {
        padding-left: 15px;
        height: 30px;
    }
    .rightNav {
        display: flex;
        justify-content: right;
        align-items: center;
        .searchIcon {
            font-size: 1.5rem;
            padding: 0 5px;
        }
        .searchBar {
            display: none;
            width: 50%;
            position: relative;
            .searchInput {
                width: calc(100% - 25px); // 25px son del padding.
                height: 2rem;
                padding: 0 15px 0 10px;
                background-color: #ffffff30;
                border: none;
                &:focus {
                    outline: none;
                }
                &::placeholder {
                    color: var(--neu-pri);
                }
            }
            .closeInput {
                position: absolute;
                font-size: 1.2rem;
                margin: 0 2px 0 0;
                font-weight: 100;
                top: calc(50% - 0.6rem);
                right: 0;
                color: var(--neu-pri);
            }
        }
        .searchBar-active {
            display: block;
            margin-right: 2%;
        }
        .primary-button,
        .secondary-button {
            margin: 0 10px 0 2px;
            padding: 3px 5px;
            border-radius: 2px;
            text-decoration: none;
        }
        .primary-button {
            background-color: var(--wooloo-pri);
            border: 1px solid var(--wooloo-pri);
            text-transform: uppercase; 
            color: #fff;
        }
        .secondary-button {
            border: 2px solid var(--wooloo-sec);
            text-transform: uppercase;
        }
    }
}
.header-active {
    background: none;
    background-color: var(--lpf-azu);
}
/* header tablet */
@media only screen and (min-width: 481px) {
    .header {
        height: 55px;
        img {
            padding: 20px;
            height: 45px;
        }
        .rightNav {
            .searchIcon {
                margin-right: 10px;
            }
            .searchBar-active {
                margin: 0 2% 0 -10px;
                .searchInput {
                    height: 2.7rem;
                    background-color: #05162599;
                }
            }
            .navbar {
                display: flex;
                .primary-button,
                .secondary-button {
                    display: block;
                    margin: 0 15px 0 0;
                    padding: 5px 10px;
                    font-size: 1.2rem;
                }
                .primary-button {
                    min-width: 65px;
                }
            }
        }
    }
}
/* header fullscreen */
@media only screen and (min-width: 769px) {
    .header {
    }
}
/* header biscreen */
@media only screen and (min-width: 1201px) {
    .header {
        height: 70px;
        img {
            padding: 20px;
            height: 50px;
        }
        .rightNav {
            .primary-button,
            .secondary-button {
                margin: 0 25px 0 0;
                padding: 5px 10px;
                font-size: 1.8rem;
            }
        }
    }
}
