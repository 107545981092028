.form-pages {
    width: 100%;
    min-height: calc(100vh - 84px);
    display: flex;
    flex-direction: column; 
    background-color: var(--wooloo-background);

    // comentar h2 y backbutton cuando los squemos. 
    p {
        font-size: 18px;
    }
    h2 {
        color: var(--pri);
        margin: 75px 15px 10px;
        font-size: 2.5rem;
        font-weight: normal;
    } 
    .backbutton { 
        a {
            margin: 10px 15px;
            color: var(--lpf-azu); 
            text-align: center;
            font-size: 2.5rem; 
            text-decoration: none; 
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .form {
        width: 90%;
        max-width: 315px;
        margin: 100px auto 0;
        display: flex;
        flex-direction: column;
        h2 {
            color: var(--wooloo-sec);
            margin: 10px 15px;
            font-size: 2.5rem;
            font-weight: normal;
        } 
        .backbutton { 
            a {
                margin: 10px 15px;
                color: var(--wooloo-pri); 
                font-size: 2.5rem; 
                text-decoration: none; 
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .my-account-label {
            color: var(--color-claro);
        }
        input, select {
            width: 90%;
            max-width: 315px;
            height: 50px;
            margin: 0 auto;
            padding: 15px;
            background-color: rgb(250 250 250);
            font-size: 1.5rem;
            font-weight: 500;
            color: #6c6161;
            line-height: 50px;
            border: 1px solid var(--neu-osc);
            border-radius: 4px;
            box-sizing: border-box;
            ::placeholder {
                color: var(--placeholders);
                font-size: 1.5rem;
            }
        }
        select {
            margin: 15px auto; 
            font-size: 1.4rem;
        }
        button {
            width: 90%;
            max-width: 315px;
            height: 50px;
            margin: 15px auto 0;
            background-color: var(--pri);
            color: var(--color-claro);
            font-size: 2rem;
            border: 0;
            border-radius: 4px;
            cursor: pointer;
        }
        // .captcha {
        //     margin: 15px auto 10px;
        // }
        .error { 
            width: 90%; 
            margin: 15px auto 0; 
            font-size: 1.5rem; 
            color: white; 
            background: red; 
        }
    }
}

@media screen and (max-width: 480px) {
    .form-pages {
        min-height: calc(100vh - 110px);
        .header {
            background-color: rgb(var(--osc));
        }
    }
}
