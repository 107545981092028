:root {
    font-size: 10px;
    --neu-pri: #bec6c4;
    --neu-osc: #adcab8;
    --pri: #41b6e5;
    --osc: 51, 63, 72;
    --color-claro: #fff;
    --background: #141414;
    --placeholders: #999999;
    --dark-color: #051626;
    --footer-text: #91b1ce;
    --auf: #209aed;
    --wooloo-pri: #ef591b;
    --wooloo-background: #202e44; 
    --wooloo-sec: #9ee6cf; 

    --sec: #daaa00;
    --ter: #c8102e;
    --neu-sec: #c5b9ac;
    --neu-ter: #c5b783; 

    // Colores LPF

    --lpf-cel: #0bbad9;  
    --lpf-azu: #00476b;  
    --lpf-ama: #f5d880; 
    --lpf-oro: #dba914; 
    --lpf-neg: #000000; 
} 

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700&family=Ubuntu:wght@300;400;500;700&family=Bebas+Neue&family=Red+Hat+Display:wght@300;400;500;700&display=swap');

@font-face {
    font-family: "UniNeueRegular";
    src: local(" UniNeueRegular"),
      url("/src/fonts/Fontfabric\ -\ UniNeueRegular.otf") format("opentype");
    font-weight: normal;
  }

* {
    color: var(--color-claro);
    margin: 0;
    font-family: 'Red Hat Display', 'UniNeueRegular', 'Open Sans', sans-serif;
}

// import app variables
//pages
@import 'landingPage';
@import 'signIn';
@import 'myAccount';
@import 'browse';
@import 'category';
@import 'terms';
@import 'forgot';
@import 'payment';
@import 'paymentOpm'; 
@import 'pack-page'; 
//components
@import 'header';
@import 'footer';
@import 'forms';
@import 'videoModal';
@import 'modalVideoCard';
@import 'destacadoInfo';
@import 'videoList';
@import 'spinner';
@import 'success';
@import 'video';
@import 'proximamente';
