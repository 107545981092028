// Payment success and payment failed pages

.success-failed-payment {
    .header {
        background: linear-gradient(
            180deg,
            rgba(20, 20, 20, 0.5) 0%,
            rgba(20, 20, 20, 0.3) 50%,
            rgba(20, 20, 20, 0) 100%
        );
        background-color: var(--auf);
        .rightNav {
            .searchIcon {
                visibility: hidden;
            }
        }
    }
    .checkout {
        margin: 40px 0 0;
        width: 100%;
        min-height: calc(100vh - 150px);
        background-color: var(--dark-color);
        display: flex;
        justify-content: center;
        align-items: center;
        .checkout-contents {
            width: 90%;
            max-width: 500px;
            background-color: var(--color-claro);
            border-radius: 5px 5px 0 0;
            .form-header {
                margin: 0 auto 20px;
                padding: 15px;
                border-radius: 5px 5px 0 0;
                background-color: var(--auf);
                font-size: 20px;
                h1 {
                    text-align: center;
                    color: var(--color-claro); 
                } 
                // Estilos para checkout internacional 
                .h1-checkout-int {
                    font-size: 24px;
                }
                button {
                    background-color: #ef591b;
                }
            } 
            h2,
            p {
                text-align: center;
                color: var(--auf);
                font-size: 20px;
            }
            sup {
                color: var(--auf);
            }
            p {
                margin: 5px 0 20px;
            }
            button {
                margin: 15px auto 20px;
                padding: 10px;
                display: block;
                text-align: center;
                font-size: 20px;
                background-color: var(--auf);
                color: var(--color-claro);
                border: none;
                border-radius: 3px;
            }
        }
        .payment-failed {
            width: 90%;
            max-width: 500px;
            background-color: var(--color-claro);
            border-radius: 5px 5px 0 0;
            .form-header {
                margin: 0 auto 20px;
                padding: 15px;
                border-radius: 5px 5px 0 0;
                background-color: var(--ter);
                font-size: 12px;
                h1 {
                    text-align: center;
                    color: var(--color-claro);
                }
            }
            h2,
            p {
                text-align: center;
                color: var(--dark-color);
                font-size: 18px;
            }
            p {
                margin: 10px 10px 20px;
            }
            .buttons {
                width: 90%;
                margin: 0 auto 25px;
                display: flex;
                justify-content: space-between;
                button {
                    width: 45%;
                    padding: 10px;
                    background-color: var(--auf);
                    border: none;
                    border-radius: 3px;
                }
                .go-to-biblioteca {
                    background-color: var(--neu-osc);
                }
            }
        }
    }
}
