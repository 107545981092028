// My Account
.my-account-page {
    .header {
        position: relative;
        background-color: rgb(var(--osc));
        .searchBar {
            display: none;
        }
    }
    .my-account {
        min-height: calc(100vh - 150px);
        background-color: var(--wooloo-background);
    }
    .responsive-account-container {
        width: 90%;
        min-height: 400px;
        min-width: 300px;
        display: block;
        position: relative;
        color: var(--dark-color);
        font-size: 1em;
        -webkit-tap-highlight-color: transparent;
        margin: 0 auto;
        h1 {
            font-size: 2.4rem;
            color: var(--color-claro);
            padding: 15px 0 15px 0px;
            font-weight: 400;
        }
        .responsive-account-content {
            .account-section {
                margin-bottom: 5px;
                background: var(--color-claro);
                border: 1px solid #999;
                padding: 10px;
                position: relative;
                .account-subsection {
                    position: relative;
                    .account-section-group {
                        .account-section-item {
                            margin-bottom: 15px;
                            display: flex;
                            justify-content: space-between;
                            align-items: baseline;
                            font-size: 1.6rem;
                            .account-section-link {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 15px 0 5px;
                                color: var(--dark-color);
                                font-weight: 400;
                                border-top: 1px solid #ccc;
                                text-decoration: none;
                            }
                            .fa-chevron-right > path {
                                color: var(--dark-color);
                            }
                            svg {
                                margin: 0 0 0 10px;
                            }
                        }
                        .account-section-email {
                            margin-top: 10px;
                            font-weight: 700;
                            overflow: hidden;
                            -o-text-overflow: ellipsis;
                            text-overflow: ellipsis;
                            color: var(--dark-color);
                        }
                        .account-section-item-disabled {
                            color: #757575;
                        }
                    }
                }
            }
        }
    }
    .salir {
        display: flex;
        justify-content: center;
        a.logout-button {
            width: 75%;
            max-width: 350px;
            height: 30px;
            margin: 15px 0;
            padding: 5px 0 0;
            text-align: center;
            background-color: var(--wooloo-pri); 
            font-size: 1.8rem;
            text-decoration: none;
            border-radius: 2px;
        }
    }
}

/* My Account Tablet */
@media only screen and (min-width: 481px) {
    .my-account-page {
        .header {
            position: relative;
        }
        .my-account {
            min-height: calc(100vh - 140px);
            background-color: #f3f3f3;
            .responsive-account-container {
                display: block;
                width: 90%;
                font-size: 1em;
                min-width: 300px;
                /* min-height: 400px; */
                position: relative;
                color: var(--dark-color);
                -webkit-tap-highlight-color: transparent;
                margin: 0 auto;
                h1 {
                    font-size: 2.4rem;
                    color: var(--color-claro);
                    padding: 15px 0 15px 0px;
                    font-weight: 400;
                }
                .responsive-account-content {
                    .account-section {
                        margin-bottom: 5px;
                        background: var(--color-claro);
                        border: 1px solid #999;
                        position: relative;
                        border-top: 1px solid #999;
                        border-right: none;
                        border-bottom: none;
                        border-left: none;
                        padding: 0;
                        background-color: inherit;
                        min-height: 4.5em;
                        .account-subsection {
                            position: relative;
                            .account-section-group {
                                float: left;
                                width: 50%;
                                background-color: inherit;
                                .account-section-item {
                                    min-height: 25px;
                                    margin-bottom: 15px;
                                    display: block;
                                    font-size: 1.6rem;
                                    .account-section-link {
                                        color: var(--auf);
                                        display: inline;
                                        border-top: none;
                                        width: 100%;
                                        padding: 15px 0px 5px;
                                        text-decoration: none;
                                        cursor: pointer;
                                    }
                                }
                                .account-section-email {
                                    margin-top: 10px;
                                    font-weight: 700;
                                    overflow: hidden;
                                    -o-text-overflow: ellipsis;
                                    text-overflow: ellipsis;
                                    color: var(--wooloo-sec);
                                }
                                .account-section-item-disabled {
                                    color: var(--color-claro);
                                    margin-bottom: 15px;
                                }
                            }
                            .account-section-group + .account-section-group {
                                margin-top: 10px;
                                text-align: right;
                                float: right;
                                width: 50%;
                                background-color: inherit;
                            }
                        }
                    }
                }
            }
        }
        .salir {
            margin-top: 175px;
            display: flex;
            justify-content: center;
            a.logout-button {
                width: 75%;
                max-width: 300px;
                height: 30px;
                font-size: 2rem;
                text-align: center;
            }
        }
    }
}
/* My Account Fullscreen */
@media only screen and (min-width: 769px) {
    .my-account-page {
        .my-account {
            min-height: calc(100vh - 140px);
        }
    }
}
/* My Account Bigscreen */
@media only screen and (min-width: 1201px) {
    .my-account-page {
        .my-account {
            min-height: calc(100vh - 154px);
            background-color: var(--wooloo-background);
        }
    }
}
